class ClassToggler {
	private togglers: HTMLElement[] = []

	constructor() {
		this.togglers = Array.from(document.querySelectorAll("[data-toggle-class]"))
		this.init()
	}

	private init() {
		this.togglers.forEach(this.initToggler.bind(this))
	}

	private initToggler(toggler: HTMLElement) {
		console.log(toggler)
		const classes = toggler.dataset.toggleClass!.split(",").map(cls => cls.trim())
		const targets = Array.from(document.querySelectorAll<HTMLElement>(toggler.dataset.toggleClassTarget!))
		const triggerEvent = toggler.dataset.toggleClassEvent ?? "hover"

		switch (triggerEvent) {
			case "hover": {
				toggler.addEventListener("mouseenter", (ev) => this.handleTogglerMouseEnter(targets, classes))
				toggler.addEventListener("mouseleave", (ev) => this.handleTogglerMouseLeave(targets, classes))
				break
			}
			case "click": {
				toggler.addEventListener("mouseenter", (ev) => this.handleTogglerClick(targets, classes))
				break
			}
			case "focus": {
				toggler.addEventListener("mouseenter", (ev) => this.handleTogglerFocus(targets, classes))
				break
			}
		}
	}

	private handleTogglerMouseEnter(targets: HTMLElement[], classes: string[]) {
		targets.forEach(target => target.classList.add(...classes))
	}

	private handleTogglerMouseLeave(targets: HTMLElement[], classes: string[]) {
		targets.forEach(target => target.classList.remove(...classes))
	}

	private handleTogglerClick(targets: HTMLElement[], classes: string[]) {

	}

	private handleTogglerFocus(targets: HTMLElement[], classes: string[]) {

	}
}

export {ClassToggler}